import React, { useState } from "react"
import { Link } from "@reach/router";
import jump from 'jump.js'

import { history } from './../routerHistory';


const Header = ( props ) => {

  const [isExpanded, toggleExpansion] = useState(false);

  function navigateTo( section ){
    jump(`section#${section}`, {
      offset: -90,
      duration: 1000
    });
  }

  function navigateToHome( section ){
    history.navigate("/#"+section, {replace: true});
  }

  
  return (
    <div className='bg-consorcio-blue fixed w-full z-10 top-0'>
      <nav className="container mx-auto flex items-center justify-between flex-wrap bg-consorcio-blue p-6 text-white">
        <div className="flex items-center flex-shrink-0 mr-6">
          <Link to='/' >
            <img src='../images/tcn_consorcio_Logo_banner_arriba.png' alt='Consorcio Logo' className='header-logo'/>
          </Link>
        </div>
        <div className="block lg:hidden">
          <button 
            className="flex items-center px-3 py-2 border cursor-pointer rounded hover:font-bold hover:border-white"
            onClick={() => toggleExpansion(!isExpanded)}
          >
            <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
          </button>
        </div>
        <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
          <div className="text-sm lg:flex-grow">
            <div 
              className={ isExpanded ? `block bg-consorcio-cyan rounded px-3 py-1` : `hidden` }
            >
              { props.light ?  <><span onClick={ ()=> navigateTo('header') } className="menu-item block mt-4 lg:inline-block lg:mt-0 hover:font-bold mr-4">
                Sobre Nosotros
              </span>
              <span onClick={ ()=> navigateTo('mission') } className="menu-item block mt-4 lg:inline-block lg:mt-0 hover:font-bold mr-4">
                Visión y Misión
              </span>
              <span onClick={ ()=> navigateTo('principios') } className="menu-item block mt-4 lg:inline-block lg:mt-0 hover:font-bold mr-4">
                Principios de Experiencia
              </span>
              <span onClick={ ()=> navigateTo('edificio') } className="menu-item block mt-4 lg:inline-block lg:mt-0 hover:font-bold mr-4">
                Edificio Consorcio
              </span>
              <span onClick={ ()=> navigateTo('historia') } className="menu-item block mt-4 lg:inline-block lg:mt-0 hover:font-bold mr-4">
                Nuestra Historia
              </span>
              <span onClick={ ()=> navigateTo('benedicios') } className="menu-item block mt-4 lg:inline-block lg:mt-0 hover:font-bold mr-4">
                Beneficios
              </span>
              <span onClick={ ()=> navigateTo('premios') } className="menu-item block mt-4 lg:inline-block lg:mt-0 hover:font-bold">
                Premios
              </span>
              <span className="menu-item block mt-4 lg:inline-block lg:mt-0 hover:font-bold">
              <a href='https://postulaciones.trabajaenconsorcio.cl/ofertas' >Oportunidades Laborales</a>
              </span> </>:<><span onClick={ ()=> navigateToHome('header') } className="menu-item block mt-4 lg:inline-block lg:mt-0 hover:font-bold mr-4">
                Sobre Nosotros
              </span>
              <span onClick={ ()=> navigateToHome('mission') } className="menu-item block mt-4 lg:inline-block lg:mt-0 hover:font-bold mr-4">
                Visión y Misión
              </span>
              <span onClick={ ()=> navigateToHome('principios') } className="menu-item block mt-4 lg:inline-block lg:mt-0 hover:font-bold mr-4">
                Principios de Experiencia
              </span>
              <span onClick={ ()=> navigateToHome('edificio') } className="menu-item block mt-4 lg:inline-block lg:mt-0 hover:font-bold mr-4">
                Edificio Consorcio
              </span>
              <span onClick={ ()=> navigateToHome('historia') } className="menu-item block mt-4 lg:inline-block lg:mt-0 hover:font-bold mr-4">
                Nuestra Historia
              </span>
              <span onClick={ ()=> navigateToHome('benedicios') } className="menu-item block mt-4 lg:inline-block lg:mt-0 hover:font-bold mr-4">
                Beneficios
              </span>
              <span onClick={ ()=> navigateToHome('premios') } className="menu-item block mt-4 lg:inline-block lg:mt-0 hover:font-bold">
                Premios
              </span><span className="menu-item block mt-4 lg:inline-block lg:mt-0 hover:font-bold">
                <a href='https://postulaciones.trabajaenconsorcio.cl/ofertas' >Oportunidades Laborales</a>
              </span> </>}
            </div>
          </div>
          <div className="hidden lg:block">
            <ul className="inline-flex">
              { props.light ?  <>
                <li><span className="px-4 font-bold text-xs menu-item" onClick={ ()=> navigateTo('header') } >Sobre Nosotros</span></li>
                <li><span className="px-4 font-bold text-xs menu-item" onClick={ ()=> navigateTo('mission') } >Visión y Misión</span></li>
                <li><span className="px-4 font-bold text-xs menu-item" onClick={ ()=> navigateTo('principios') } >Principios de Experiencia</span></li>
                <li><span className="px-4 font-bold text-xs menu-item" onClick={ ()=> navigateTo('edificio') } >Edificio Consorcio</span></li>
                <li><span className="px-4 font-bold text-xs menu-item" onClick={ ()=> navigateTo('historia') } >Nuestra Historia</span></li>
                <li><span className="px-4 font-bold text-xs menu-item" onClick={ ()=> navigateTo('benedicios') } >Beneficios</span></li>
                <li><span className="px-4 font-bold text-xs menu-item" onClick={ ()=> navigateTo('premios') } >Premios</span></li>
                </> : <><li><span className="px-4 font-bold text-xs menu-item" onClick={ ()=> navigateToHome('header') } >Sobre Nosotros</span></li>
                <li><span className="px-4 font-bold text-xs menu-item" onClick={ ()=> navigateToHome('mission') } >Visión y Misión</span></li>
                <li><span className="px-4 font-bold text-xs menu-item" onClick={ ()=> navigateToHome('principios') } >Principios de Experiencia</span></li>
                <li><span className="px-4 font-bold text-xs menu-item" onClick={ ()=> navigateToHome('edificio') } >Edificio Consorcio</span></li>
                <li><span className="px-4 font-bold text-xs menu-item" onClick={ ()=> navigateToHome('historia') } >Nuestra Historia</span></li>
                <li><span className="px-4 font-bold text-xs menu-item" onClick={ ()=> navigateToHome('benedicios') } >Beneficios</span></li>
                <li><span className="px-4 font-bold text-xs menu-item" onClick={ ()=> navigateToHome('premios') } >Premios</span></li></>
              }
              <li><a href='https://postulaciones.trabajaenconsorcio.cl/ofertas' ><span className="px-4 font-bold text-sm" >Oportunidades Laborales</span></a></li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Header
