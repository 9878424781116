import React, { useEffect } from "react"
import { Link } from "@reach/router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import jump from 'jump.js'

import Layout from "../components/layout"
import SEO from "../components/seo"

import HistoryItem from '../components/HistoryItem'
import HoverBox from '../components/HoverBox'
import SlideBox from '../components/SlideBox'

const awards = [
  {
    icon: '../images/tcn_consorcio_Dinero_2_.png',
    year: '2019',
    title: 'Premio',
    desc: 'Merco Talento'
  },
  {
    icon: '../images/tcn_consorcio_Premio.png',
    year: '2016',
    title: '1er Lugar',
    desc: 'como Mejor Compañía de Seguros de Vida en Chile'
  },
  {
    icon: '../images/tcn_consorcio_Premio_4.png',
    year: '2013',
    title: '1er Lugar',
    desc: 'Reputación Corporativa Categoría Compañías de Seguros'
  },
  {
    icon: '../images/tcn_consorcio_Premio_5.png',
    year: '2012',
    title: 'Ganadores',
    desc: '(Plata) de premios big!'
  },
  {
    icon: '../images/tcn_consorcio_IZO.png',
    year: '2009 y 2011',
    title: '1er Lugar',
    desc: 'de Empresa que genera la Mejor Experiencia en sus Clientes'
  },
  {
    icon: '../images/tcn_consorcio_Premio_6.png',
    year: '2009 y 2011',
    title: 'Ganadores',
    desc: '(plata) de Effie Awards'
  },
  {
    icon: '../images/tcn_consorcio_Superbrands.png',
    year: '2006',
    title: 'Premiados',
    desc: 'por Superbrands'
  },
]

const IndexPage = (props) => {

  useEffect(() => {
    const { location } = props;
    try {
      if (location.hash !== ""){
        console.log(location.hash)
        jump(`section${location.hash}`, {
          offset: -70,
          duration: 1000
        });
      }
    } catch (error) {
      console.log('Route error')
    }
  }, []);
  return (
    <Layout page='home'>
      <SEO title="Consorcio - Trabaja con Nosotros" />
      <section className='hero flex flex-col bg-white h-auto text-consorcio-blue px-5 lg:px-12' id='header'>
        <div className='lg:w-4/12'>
          <h1 className='title text-4xl font-bold '> En Consorcio </h1>
          <p className='main-text p-1 lg:text-base text-justify lg:text-left lg:leading-loose'>buscamos generar <strong>relaciones de confianza y de largo plazo</strong> no solo con nuestros clientes, distribuidores e intermediarios, <strong> sino también con nuestros colaboradores y entorno comunitario.</strong></p>
          <div className='flex flex-row h-auto mt-6 lg:mt-12'>
            <div className='bg-consorcio-blue rounded-tl-lg rounded-bl-lg text-white h-auto p-3 font-bold text-sm lg:text-sm '>
              <a href='https://postulaciones.trabajaenconsorcio.cl/ofertas' >Conoce las oportunidades que tenemos para ti, y ¡sé parte de Consorcio!</a>
            </div>
            <div className='bg-consorcio-green rounded-tr-lg rounded-br-lg text-white h-auto p-3 flex flex-col justify-center '>
              <a href='https://postulaciones.trabajaenconsorcio.cl/ofertas' ><FontAwesomeIcon icon={faChevronRight} className='text-4xl' /></a>
            </div>
          </div>
        </div>
      </section>
      <section className='flex flex-col lg:flex-row bg-white h-auto text-consorcio-blue px-5 lg:pt-24 lg:px-12 bg-gray-100' id='mission'>
          <div className='flex flex-1 lg:mr-16 lg:mt-6'>
            <img alt='Mission' src='../images/tcn_consorcio_Foto_misión.png' />
          </div>
          <div className='flex flex-col flex-1'>
            <h1 className='title text-4xl lg:text-5xl font-bold mt-2 lg:mt-3'> Misión </h1>
            <p className='p-text'>Somos una organización que ofrece una variedad de servicios financieros de excelencia para permitir a nuestros clientes satisfacer sus necesidades de prosperidad familiar y seguridad patrimonial.</p>
            <p className='p-text'>Creamos valor para nuestros accionistas construyendo relaciones de confianza con quienes interactuamos y cumpliendo nuestro rol de buen ciudadano empresarial.</p>
            <p className='p-text'>Ofrecemos las condiciones para que las personas en nuestra organización desarrollen todo su potencial, en un ambiente laboral desafiante y de respeto a los valores que profesamos.</p>
          </div>
        </section>
      <section className='flex flex-col lg:flex-row bg-white h-auto text-consorcio-blue px-5 lg:px-12 bg-gray-100' id='vision'>
        <div className='flex flex-1 lg:mr-16 lg:mt-6'>
          <img alt='Mission' src='../images/tcn_consorcio_Foto_vision.png' />
        </div>
        <div className='flex flex-col flex-1'>
          <h1 className='title text-4xl lg:text-5xl font-bold mt-2 lg:mt-3'> Visión </h1>
          <p className='p-text'>Ser una compañía que resuelve todas las necesidades financieras de nuestros clientes, entregándoles la mejor experiencia personal y digital, sustentada en nuestra capacidad de conocerlos y personalizando el servicio que les entregamos.</p>
        </div>
      </section>
      <section className='flex flex-col bg-white h-auto text-consorcio-blue px-5 lg:px-12' id='principios'>
        <div className='flex flex-col'>
          <h1 className='title text-4xl lg:text-5xl font-bold mt-2 lg:mt-3'> Nuestros principios <br /> de experiencia </h1>
        </div>
        <div className='flex flex-col lg:flex-row mt-8'>
          {/* First Colunm */}
          <div className='flex flex-col flex-1 lg:mr-4'>
            <div className='flex flex-1 flex-col lg:flex-row pb-10'>
              <div className=' lg:hidden lg:flex flex flex-col items-center justify-center bg-consorcio-cyan lg:w-1/3 py-4'>
                <img alt='Simple ' src='../images/tcn_consorcio_Simple_icono.png' className='w-12 m-4' />
                <strong className='text-white text-2xl'>Simple</strong>
              </div>
              <div className='bg-consorcio-cyan lg:w-2/3 pt-8 pl-6 pr-6 pb-6 text-center lg:text-right mt-2 lg:mt-0 lg:mr-2'>
                <p className='text-boxes'>Desarrollamos e implementamos procesos, productos y comunicaciones simples, fáciles de entender y con las instancias que requieras.</p>
              </div>
              <div className='hidden lg:block lg:flex flex-col items-center justify-center bg-consorcio-cyan lg:w-1/3'>
                <img alt='Simple ' src='../images/tcn_consorcio_Simple_icono.png' className='w-12 m-4' />
                <strong className='text-white text-2xl'>Simple</strong>
              </div>
            </div>
          
            <div className='flex flex-1 flex-col lg:flex-row pb-10'>
              <div className=' lg:hidden lg:flex flex flex-col items-center justify-center bg-consorcio-blue lg:w-1/3 py-4'>
                <img alt='Responsable ' src='../images/tcn_consorcio_Responsable_icono.png' className='w-16 m-4' />
                <strong className='text-white text-2xl'>Responsable</strong>
              </div>
              <div className='bg-consorcio-blue lg:w-2/3 pt-8 pl-6 pr-6 pb-6 text-center lg:text-right mt-2 lg:mt-0 lg:mr-2'>
                <p className='text-boxes'>Cumplimos lo que te prometemos, siendo responsables de principio a fin, frente a cada requerimiento o solución que necesites.</p>
              </div>
              <div className='hidden lg:block lg:flex flex-col items-center justify-center bg-consorcio-blue lg:w-1/3'>
                <img alt='Responsable ' src='../images/tcn_consorcio_Responsable_icono.png' className='w-16 m-4' />
                <strong className='text-white text-2xl'>Responsable</strong>
              </div>
            </div>
          </div>
          {/* Second Colunm */}
          <div className='flex flex-col flex-1 lg:ml-4'>
            <div className='flex flex-1 flex-col lg:flex-row pb-10'>
              <div className='flex flex-col items-center justify-center bg-consorcio-green lg:w-1/3 py-4'>
                <img alt='Cercana ' src='../images/tcn_consorcio_Cercana_icono.png' className='w-20 m-4' />
                <strong className='text-white text-2xl'>Cercana</strong>
              </div>
              <div className='bg-consorcio-green lg:w-2/3 pt-8 pl-6 pr-6 pb-6 mt-2 lg:mt-0 lg:ml-2'>
                <p className='text-boxes'>Entendemos y nos anticipamos a sus necesidades, para entregarte soluciones en producto, asesoría e información que se ajusten a tu realidad.</p>
              </div>
            </div>
          
            <div className='flex flex-1 flex-col lg:flex-row pb-10'>
              <div className='flex flex-col items-center justify-center bg-white lg:w-1/3 py-4 border-consorcio-blue '>
                <img alt='Ágil ' src='../images/tcn_consorcio_Icono_Ágil.png' className='w-16 m-4' />
                <strong className='text-2xl'>Ágil</strong>
              </div>
              <div className='bg-white lg:w-2/3 pt-8 pl-6 pr-6 pb-6 mt-2 lg:mt-0 lg:ml-2 border-consorcio-blue '>
                <p className='text-boxes-o'>Resolvemos tus requerimientos con la agilidad y omnicanalidad que necesitas, con procesos que buscan evitar demoras y duplicación en tu atención.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='flex flex-col lg:flex-row h-auto text-consorcio-blue px-5 lg:px-12 bg-gray-100' id='edificio'>
        <div className='flex flex-col lg:w-5/12  px-2 lg:px-0 lg:pr-8'>
          <h1 className='title text-4xl text-center lg:text-5xl font-bold mt-2 mb-4 lg:mb-16 lg:mt-12'> Edificio Consorcio Santiago </h1>
          <p className='p-text'>Una infraestructura icónica a nivel internacional de lo que pueden ser los edificios verdes y sustentables, donde destaca su capacidad para reducir la absorción solar y donde quienes habitan el edificio pueden admirar el paso de las estaciones desde una vista vanguardista y ecológica.</p>
          <p className='p-text'>Con su innovadora fachada vegetal, el edificio fue el primero en el mundo en su tipo. El símbolo institucional de Consorcio, que cuenta con más de un cuarto de siglo de trayectoria, sigue siendo un referente internacional de la arquitectura ambientalmente consciente. </p>
        </div>
        <div className='flex lg:w-7/12'>
          <img alt='edificio' src='../images/tcn_consorcio-14.png' className='w-full' />
        </div>
      </section>
      <section className='flex flex-col h-auto text-consorcio-blue pt-1 pb-3 px-5 lg:py-6 lg:px-12' id='historia'>
        <h1 className='title text-4xl lg:text-5xl font-bold mt-2 lg:mt-3'> Nuestra historia </h1>
        <div className='items hidden lg:block mt-2'>
          
          <div className='history-item flex flex-row h-20'>
            <div className='history-item-text-left w-5/12 '>
            </div>
            <div className='h-auto w-2/12'>
              <HistoryItem icon='hi-top' year='1916'/> 
            </div>
            <div className='history-item-text-right w-5/12'>
              <span className='lg:pt-3'><strong>Fundación de la Compañía de Seguros La industrial</strong></span>
            </div>
          </div>
          
          <div className='history-item flex flex-row h-20'>
            <div className='history-item-text-left w-5/12 '>
              <span><strong>La empresa cambia de nombre</strong> <br /> a compañía de Seguros de Vida Consorcio <br /> Nacional de Seguros S.A</span>
            </div>
            <div className='h-auto w-2/12'>
              <HistoryItem icon='hi-green-left' year='1975'/> 
            </div>
            <div className='history-item-text-right w-5/12'>
            </div>
          </div>
          
          <div className='history-item flex flex-row h-20'>
            <div className='history-item-text-left w-5/12 '>
            </div>
            <div className='h-auto w-2/12'>
              <HistoryItem icon='hi-cyan-right' year='1987'/> 
            </div>
            <div className='history-item-text-right w-5/12'>
              <span className='lg:pt-3'><strong>Consorcio crea la fundación Consorcio</strong> <br /> para apoyar el desarrollo y progreso social del país.</span>
            </div>
          </div>

          <div className='history-item flex flex-row h-20'>
            <div className='history-item-text-left w-5/12 '>
              <span><strong>Se inaugura el Edificio Corporativo</strong> <br /> de Consorcio, reconocido hoy <br /> como moderno y emblemático.</span>
            </div>
            <div className='h-auto w-2/12'>
              <HistoryItem icon='hi-blue-left' year='1993'/> 
            </div>
            <div className='history-item-text-right w-5/12'>
            </div>
          </div>

          <div className='history-item flex flex-row h-20'>
            <div className='history-item-text-left w-5/12 '>
            </div>
            <div className='h-auto w-2/12'>
              <HistoryItem icon='hi-green-right' year='2002'/> 
            </div>
            <div className='history-item-text-right w-5/12'>
              <span className='lg:pt-3'><strong>Fundación Consorcio inaugura Colegio Monte Olivo en Puente Alto.</strong> <br /> En apoyo a niños con vulnerabilidad social.</span>
            </div>
          </div>

          <div className='history-item flex flex-row h-20'>
            <div className='history-item-text-left w-5/12 '>
              <span><strong>Consorcio lanza el primer portal</strong> <br /> de venta de seguros de auto y viajes en línea.</span>
            </div>
            <div className='h-auto w-2/12'>
              <HistoryItem icon='hi-cyan-left' year='2008'/> 
            </div>
            <div className='history-item-text-right w-5/12'>
            </div>
          </div>

          <div className='history-item flex flex-row h-20'>
            <div className='history-item-text-left w-5/12 '>
            </div>
            <div className='h-auto w-2/12'>
              <HistoryItem icon='hi-blue-right' year='2009'/> 
            </div>
            <div className='history-item-text-right w-5/12'>
              <span className='lg:pt-3'><strong>Creación Banco Consorcio.</strong></span>
            </div>
          </div>

          <div className='history-item flex flex-row h-20'>
            <div className='history-item-text-left w-5/12 '>
              <span><strong>Lanzamiento de las aplicaciones móviles</strong> <br /> de Consorcio Seguros Vida y de Consorcio <br /> Corredores de Bolsa.</span>
            </div>
            <div className='h-auto w-2/12'>
              <HistoryItem icon='hi-green-left' year='2010'/> 
            </div>
            <div className='history-item-text-right w-5/12'>
            </div>
          </div>
          
          <div className='history-item flex flex-row h-20'>
            <div className='history-item-text-left w-5/12 '>
            </div>
            <div className='h-auto w-2/12'>
              <HistoryItem icon='hi-cyan-right' year='2012'/> 
            </div>
            <div className='history-item-text-right w-5/12'>
              <span className='lg:pt-3'><strong> El holding lidera en utilidades en la industria de seguros nacional,</strong> <br /> considerando los resultados sumados de las compañías de <br/> Seguros de Vida y Generales. </span>
            </div>
          </div>

          <div className='history-item flex flex-row h-20'>
            <div className='history-item-text-left w-5/12 '>
              <span>Consorcio Financiero S.A. ingresa al <br/> <strong>mercado de seguros de vida de Perú</strong> <br />con la compra del 40,1% de la Compañía de <br /> Seguros La Positiva Vida Seguros y Reaseguros S.A.</span>
            </div>
            <div className='h-auto w-2/12'>
              <HistoryItem icon='hi-blue-left' year='2015'/> 
            </div>
            <div className='history-item-text-right w-5/12'>
            </div>
          </div>

          <div className='history-item flex flex-row h-20'>
            <div className='history-item-text-left w-5/12 '>
            </div>
            <div className='h-auto w-2/12'>
              <HistoryItem icon='hi-green-right' year='2016'/> 
            </div>
            <div className='history-item-text-right w-5/12'>
              <span className='lg:pt-3'><strong>Grupo Consorcio alcanza un récord al convertirse en el primer actor del sector</strong> <br /> superando los US$1.321 millones en ingresos por prima en las compañías de seguros.  Banco Consorcio es la entidad más eficiente del sistema financiero local del año, con un índice de 29,8%. </span>
            </div>
          </div>

          <div className='history-item flex flex-row h-20'>
            <div className='history-item-text-left w-5/12 '>
              <span><strong>Se estrena nueva visión</strong> que recoge el cambio que está llevando <br /> adelante en cuanto a su centralidad en el cliente y <br />  la amplitud de su oferta de productos y servicios financieros. </span>
            </div>
            <div className='h-auto w-2/12'>
              <HistoryItem icon='hi-cyan-left' year='2018'/> 
            </div>
            <div className='history-item-text-right w-5/12'>
            </div>
          </div>
        </div>

        <div className='items lg:hidden mt-2'>
          <div className='history-item flex flex-row'>
            <div className='h-auto w-2/12'>
              <HistoryItem icon='hi-top' year='1916'/> 
            </div>
            <div className='history-item-text-right w-5/12'>
              <span className='lg:pt-3'><strong>Fundación de la Compañía de Seguros La industrial</strong></span>
            </div>
          </div>
          
          <div className='history-item flex flex-row'>
            <div className='h-auto w-2/12'>
              <HistoryItem icon='hi-green-left' year='1975'/> 
            </div>
            <div className='history-item-text-left w-10/12 '>
              <span><strong>La empresa cambia de nombre</strong> a compañía de Seguros de Vida Consorcio Nacional de Seguros S.A</span>
            </div>
          </div>
          
          <div className='history-item flex flex-row'>
            <div className='h-auto w-2/12'>
              <HistoryItem icon='hi-cyan-right' year='1987'/> 
            </div>
            <div className='history-item-text-right w-10/12'>
              <span className='lg:pt-3'><strong>Consorcio crea la fundación Consorcio</strong> para apoyar el desarrollo y progreso social del país.</span>
            </div>
          </div>

          <div className='history-item flex flex-row'>
            <div className='h-auto w-2/12'>
              <HistoryItem icon='hi-blue-left' year='1993'/> 
            </div>
            <div className='history-item-text-left w-10/12 '>
              <span><strong>Se inaugura el Edificio Corporativo</strong> de Consorcio, reconocido hoy como moderno y emblemático.</span>
            </div>
          </div>

          <div className='history-item flex flex-row'>
            <div className='h-auto w-2/12'>
              <HistoryItem icon='hi-green-right' year='2002'/> 
            </div>
            <div className='history-item-text-right w-10/12'>
              <span className='lg:pt-3'><strong>Fundación Consorcio inaugura Colegio Monte Olivo en Puente Alto.</strong> En apoyo a niños con vulnerabilidad social.</span>
            </div>
          </div>

          <div className='history-item flex flex-row'>
            <div className='h-auto w-2/12'>
              <HistoryItem icon='hi-cyan-left' year='2008'/> 
            </div>
            <div className='history-item-text-left w-10/12 '>
              <span><strong>Consorcio lanza el primer portal</strong> de venta de seguros de auto y viajes en línea.</span>
            </div>
          </div>

          <div className='history-item flex flex-row'>
            <div className='h-auto w-2/12'>
              <HistoryItem icon='hi-blue-right' year='2009'/> 
            </div>
            <div className='history-item-text-right w-10/12'>
              <span className='lg:pt-3'><strong>Creación Banco Consorcio.</strong></span>
            </div>
          </div>

          <div className='history-item flex flex-row'>
            <div className='h-auto w-2/12'>
              <HistoryItem icon='hi-green-left' year='2010'/> 
            </div>
            <div className='history-item-text-left w-10/12 '>
              <span><strong>Lanzamiento de las aplicaciones móviles</strong> de Consorcio Seguros Vida y de Consorcio Corredores de Bolsa.</span>
            </div>
          </div>
          
          <div className='history-item flex flex-row'>
            <div className='h-auto w-2/12'>
              <HistoryItem icon='hi-cyan-right' year='2012'/> 
            </div>
            <div className='history-item-text-right w-10/12'>
              <span className='lg:pt-3'><strong> El holding lidera en utilidades en la industria de seguros nacional,</strong> considerando los resultados sumados de las compañías de Seguros de Vida y Generales. </span>
            </div>
          </div>

          <div className='history-item flex flex-row'>
            <div className='h-auto w-2/12'>
              <HistoryItem icon='hi-blue-left' year='2015'/> 
            </div>
            <div className='history-item-text-left w-10/12 '>
              <span>Consorcio Financiero S.A. ingresa al <strong>mercado de seguros de vida de Perú</strong> con la compra del 40,1% de la Compañía de Seguros La Positiva Vida Seguros y Reaseguros S.A.</span>
            </div>
          </div>

          <div className='history-item flex flex-row'>
            <div className='h-auto w-2/12'>
              <HistoryItem icon='hi-green-right' year='2016'/> 
            </div>
            <div className='history-item-text-right w-10/12'>
              <span className='lg:pt-3'><strong>Grupo Consorcio alcanza un récord al convertirse en el primer actor del sector</strong> superando los US$1.321 millones en ingresos por prima en las compañías de seguros. Banco Consorcio es la entidad más eficiente del sistema financiero local del año, con un índice de 29,8%. </span>
            </div>
          </div>

          <div className='history-item flex flex-row'>
            <div className='h-auto w-2/12'>
              <HistoryItem icon='hi-cyan-left' year='2018'/> 
            </div>
            <div className='history-item-text-left w-10/12 '>
              <span><strong>Se estrena nueva visión</strong> que recoge el cambio que está llevando adelante en cuanto a su centralidad en el cliente y la amplitud de su oferta de productos y servicios financieros. </span>
            </div>
          </div>
        </div>
      </section>
      <section className='flex flex-col lg:flex-col h-auto text-consorcio-blue bg-gray-100  px-5 lg:py-6 lg:px-12' id='benedicios'>
        <div className='flex flex-col lg:flex-row w-full block lg:hidden'>
          <div className='flex flex-col w-full mx-0 items-center justify-center py-4'>
            <h1 className='title text-4xl lg:text-5xl font-bold mt-0 lg:mt-0'> Beneficios </h1>
          </div>
        </div>
        <div className='flex flex-col lg:flex-row w-full '>
          <div className='flex flex-col items-center justify-center w-full lg:w-5/12 pb-10 lg:pb-20'>
            <SlideBox
              icon={'../images/tcn_consorcio_Familia.png'}
              title={'Porque cada momento es valioso y queremos que disfrutes al máximo tu tiempo libre.'}
              frontBgClass={'bg-consorcio-cyan'}
            >
              <ul className="list-disc text-left">
                <li>Día cumpleaños. </li>
                <li>Día administrativo.</li>
                <li>Convenios.</li>
                <li>Happy Friday.</li>
              </ul>
            </SlideBox>
          </div>
          <div className='flex flex-col items-center justify-end w-2/12 '>
            <img src='../images/up-lines.svg' className='hidden lg:block' alt='line' />
          </div>
          <div className='flex flex-col items-center justify-center w-full lg:w-5/12  pb-0 lg:pb-20'>
          <SlideBox
              icon={'../images/tcn_consorcio_Casita.png'}
              title={'En Consorcio nos interesamos por ti y tu familia, por eso, apoyarte marca la diferencia.'}
              frontBgClass={'bg-consorcio-green'}
            >
              <ul className="list-disc text-left">
                <li>Asignación familiar suplementaria.</li>
                <li>Asignación escolar.</li>
              </ul>
            </SlideBox>
          </div>
        </div>
        <div className='flex flex-col lg:flex-row w-full hidden lg:block'>
          <div className='flex flex-col w-full mx-0 items-center justify-center py-4'>
            <h1 className='title text-4xl lg:text-5xl font-bold mt-0 lg:mt-0'> Beneficios </h1>
          </div>
        </div>
        <div className='flex flex-col lg:flex-row w-full'>
          <div className='flex flex-col items-center justify-center w-full lg:w-5/12 pt-10 lg:pt-20'>
            <SlideBox
              icon={'../images/tcn_consorcio_Botiquín.png'}
              title={'Porque somos especialistas en nuestro rubro queremos cuidarte para que te sientas seguro y respaldado en todo momento, por eso contamos con:'}
              frontBgClass={'bg-consorcio-blue'} 
            >
              <ul className="list-disc text-left">
                <li>Préstamos de auxilio.</li>
                <li>Préstamos senior.</li>
                <li>Seguro de vida.</li>
                <li>Seguro catastrófico.</li>
                <li>Subsidio por licencia. </li>
              </ul>
            </SlideBox>
          </div>
          <div className='flex flex-col items-center justify-start w-2/12'>
            <img src='../images/down-lines.svg' className='hidden lg:block' alt='line'/>
          </div>
          <div className='flex flex-col items-center justify-center w-full lg:w-5/12 pt-10 lg:pt-20'>
            <SlideBox
                icon={'../images/tcn_consorcio_Dinero.png'}
                title={'Como eres parte de nuestros logros, retribuimos tu esfuerzo otorgándote apoyo en distintos periodos del año.'}
                frontBgClass={'bg-white '}
                bordered={ '#003DA5' }
              >
                <ul className="list-disc text-left">
                  <li>Bonos.</li>
                  <li>Aguinaldos.</li>
                </ul>
              </SlideBox>
          </div>
        </div>

      </section>
      <section className='flex flex-col lg:flex-col h-auto text-consorcio-blue px-5 lg:px-12' id='premios'>
        <h1 className='title text-4xl lg:text-5xl font-bold mt-2 lg:mt-3'> Premios </h1>
        <div className='flex flex-col lg:flex-row w-full pt-0 lg:pt-10'>
          { awards.map((item) =>(
            <div className='flex flex-col flex-1 mt-8 lg:mt-0' key={ item.icon.toString() }>
              <div className="max-w-sm flex flex-col justify-center items-center ">
                <img className="w-full award-pic" src={ item.icon } alt="adwars" />  
              </div>
              <div className="max-w-sm flex flex-col justify-center items-center ">
                <div className="font-bold text-xl leading-normal "> { item.year } </div>
                <div className="text-xl leading-normal"> { item.title } </div>
                <div className="flex flex-col items-center justify-center  leading-normal mx-4 lg:mx-2 ">
                  <p className="text-center">
                    { item.desc }
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      
    </Layout>
  );
};

export default IndexPage