import React from "react"
import jump from 'jump.js'
import { Link } from "@reach/router";

import { history } from './../routerHistory';

const Footer = ( props ) => {

  function navigateTo( section ){
    jump(`section#${section}`, {
      offset: -90,
      duration: 1000
    });
  }
  
  function navigateToHome( section ){
    history.navigate("/#"+section, {replace: true});
  }
  
  return (
    <div className='bg-consorcio-blue w-full z-10 top-0'>
      <nav className="container mx-auto flex flex-col items-start justify-between bg-consorcio-blue p-0 lg:p-6 text-white">
        <div className="w-full block lg:flex lg:items-center lg:w-auto">
          <div className="hidden lg:block">
            <ul className="inline-flex">
              { props.light ?  <><li><span className="px-4 font-bold menu-item-footer" onClick={ ()=> navigateTo('header') } >Sobre Nosotros</span></li>
              <li><span className="px-4 font-bold menu-item-footer" onClick={ ()=> navigateTo('mission') } >Visión y Misión</span></li>
              <li><span className="px-4 font-bold menu-item-footer" onClick={ ()=> navigateTo('principios') } >Principios de Experiencia</span></li>
              <li><span className="px-4 font-bold menu-item-footer" onClick={ ()=> navigateTo('edificio') } >Edificio Consorcio</span></li>
              <li><span className="px-4 font-bold menu-item-footer" onClick={ ()=> navigateTo('historia') } >Nuestra Historia</span></li>
              <li><span className="px-4 font-bold menu-item-footer" onClick={ ()=> navigateTo('benedicios') } >Beneficios</span></li>
              <li><span className="px-4 font-bold menu-item-footer" onClick={ ()=> navigateTo('premios') } >Premios</span></li>
              </> : <><li><span className="px-4 font-bold menu-item-footer" onClick={ ()=> navigateToHome('header') } >Sobre Nosotros</span></li>
              <li><span className="px-4 font-bold menu-item-footer" onClick={ ()=> navigateToHome('mission') } >Visión y Misión</span></li>
              <li><span className="px-4 font-bold menu-item-footer" onClick={ ()=> navigateToHome('principios') } >Principios de Experiencia</span></li>
              <li><span className="px-4 font-bold menu-item-footer" onClick={ ()=> navigateToHome('edificio') } >Edificio Consorcio</span></li>
              <li><span className="px-4 font-bold menu-item-footer" onClick={ ()=> navigateToHome('historia') } >Nuestra Historia</span></li>
              <li><span className="px-4 font-bold menu-item-footer" onClick={ ()=> navigateToHome('benedicios') } >Beneficios</span></li>
              <li><span className="px-4 font-bold menu-item-footer" onClick={ ()=> navigateToHome('premios') } >Premios</span></li></> }
              <li> <a href='https://postulaciones.trabajaenconsorcio.cl/ofertas'><span className="px-4 font-bold text-sm" >Oportunidades Laborales</span></a></li>
            </ul>
          </div>
        </div>
        <div className="w-full hidden lg:flex lg:items-center lg:w-full lg:p-4">
          <hr className='footer-line' />
        </div>
        <div className="w-full flex flex-row lg:flex-row lg:items-center lg:w-full pt-2 lg:p-4 justify-center lg:hidden">
          <span className=''>Síguenos en nuestras Redes Sociales</span> 
        </div>
        <div className="w-full flex flex-row lg:flex-row lg:items-center lg:w-full p-2 lg:p-4 justify-center lg:justify-end ">
          <span className='hidden lg:block'>Síguenos en nuestras Redes Sociales</span> 
          <span className='px-4'>
            <a href='https://www.linkedin.com/company/consorcio/' rel='noopener noreferrer' target='_blank' ><img className='img-rrss' src='/images/tcn_consorcio_Linkedin.png' alt='Linkedin' /></a>
          </span> 
          <span className='px-4'>
            <a href='https://www.facebook.com/ConsorcioCL/' rel='noopener noreferrer' target='_blank' ><img className='img-rrss' src='/images/tcn_consorcio_Facebook.png' alt='Facebook' /></a>
          </span> 
          <span className='px-4'>
            <a href='https://twitter.com/Consorcio_CL' rel='noopener noreferrer' target='_blank' ><img className='img-rrss' src='/images/tcn_consorcio_Twitter.png' alt='Twitter' /></a>
          </span> 
          <span className='px-4'>
            <a href='https://www.youtube.com/user/consorciocl' rel='noopener noreferrer' target='_blank' ><img className='img-rrss' src='/images/tcn_consorcio_Youtube.png' alt='Youtube' /></a>
          </span> 
          
        </div>
      </nav>
    </div>
  )
}

export default Footer