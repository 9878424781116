import React from "react"
import { Link } from "@reach/router";

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className='p-32'>
    <h1 className='title text-4xl text-blue-900 py-2 font-bold '> Página no encontrada </h1>
    <p className='main-text p-1 lg:text-base text-justify lg:text-left lg:leading-loose'>Volver al <strong> <Link to='/'> inicio </Link> </strong></p>
    </section>

  </Layout>
)

export default NotFoundPage
